<template>
  <ion-page>
    <ion-content
        style="--background:#07253B url('https://static.yuzhangmen.cn/custom/aboutau/about-au-bg.png') 0 0/100% 399px no-repeat;">
      <div style="margin-top: 100px; text-align: center;"><img
          src="https://static.yuzhangmen.cn/custom/aboutau/about-au-font.png" width="161"/></div>
      <div style="padding:20px; text-align: center;color: white">
        作为全球热门移民国家之一,澳大利亚凭着各种各样的优势,生活质量,宜居指数,教育体系以及福利方面都吸引着成千上万的海外人士
      </div>
      <ion-grid>
        <ion-row>
          <ion-col size="6" style="--ion-grid-column-padding: 0" router-link="/about-au-detail/1">
            <div class="content-box"
                 style="background: url('https://static.yuzhangmen.cn/custom/aboutau/about-au-1.png') 0 0/100% auto no-repeat;">
              移民福利
            </div>
          </ion-col>
          <ion-col size="6" router-link="/about-au-detail/2">
            <div class="content-box"
                 style="background: url('https://static.yuzhangmen.cn/custom/aboutau/about-au-2.png') 0 0/100% auto no-repeat;">
              人口民族
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="6" router-link="/about-au-detail/3">
            <div class="content-box"
                 style="background: url('https://static.yuzhangmen.cn/custom/aboutau/about-au-3.png') 0 0/100% auto no-repeat;">
              地理环境
            </div>
          </ion-col>
          <ion-col size="6" router-link="/about-au-detail/4">
            <div class="content-box"
                 style="background: url('https://static.yuzhangmen.cn/custom/aboutau/about-au-4.png') 0 0/100% auto no-repeat;;">
              教育就业
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="6" router-link="/about-au-detail/5">
            <div class="content-box"
                 style="background: url('https://static.yuzhangmen.cn/custom/aboutau/about-au-5.png') 0 0/100% auto no-repeat;;">
              时差概况
            </div>
          </ion-col>
          <ion-col size="6" router-link="/about-au-detail/6">
            <div class="content-box"
                 style="background: url('https://static.yuzhangmen.cn/custom/aboutau/about-au-6.png') 0 0/100% auto no-repeat;;">
              经济发展
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {useRoute, useRouter} from "vue-router";
import {IonContent, IonGrid, IonRow, IonCol, useIonRouter, IonPage} from '@ionic/vue';

export default {
  name: "AboutAu",
  data() {
    return {
      route: useRoute(),
      router: useRouter(),
      ionRouter: useIonRouter(),
      index: 1
    }
  },
  components: {
    IonContent, IonGrid, IonRow, IonCol, IonPage
  }
}
</script>

<style scoped>
ion-col {
  --ion-grid-column-padding: 0;
}

.content-box {
  text-align: center;
  line-height: 141px;
  height: 141px;
  width: 186px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  padding-right: 30px;
  margin-left: 8px;
}
</style>